import { Entity, LiveData } from '@toeverything/infra';

import type { Tag } from '../entities/tag';

export class TagTreeNode extends Entity<{
  displayName: string;
  prefix: string;
}> {
  displayName = this.props.displayName;
  prefix = this.props.prefix;
  constructor() {
    super();
  }

  private readonly tags$ = new LiveData<Set<Tag>>(new Set());
  readonly children$ = new LiveData<Map<string, TagTreeNode>>(new Map());

  getOrCreateNode(nodeName: string) {
    let child = this.children$.value.get(nodeName);
    if (!child) {
      child = this.framework.createEntity(TagTreeNode, {
        displayName: nodeName,
        prefix: this.getTreePath(),
      });
      const newMap = new Map(this.children$.getValue());
      newMap.set(nodeName, child);
      this.children$.next(newMap);
    }
    return child;
  }

  addTag(tag: Tag) {
    const newSet = new Set(this.tags$.getValue());
    newSet.add(tag);
    this.tags$.next(newSet);
  }

  getTreePath() {
    return this.prefix + '/' + this.displayName;
  }

  allTagIds$ = LiveData.computed(get => {
    const myIds = new Set<string>();
    get(this.tags$).forEach(tag => myIds.add(tag.id));
    for (const child of get(this.children$).values()) {
      get(child.allTagIds$).forEach(id => myIds.add(id));
    }
    return myIds;
  });

  clear() {
    this.tags$.value.clear();
    this.children$.value.clear();
  }
}
