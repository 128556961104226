import type { TagList } from '@affine/core/modules/tag/entities/tag-list';
import { TagTreeNode } from '@affine/core/modules/tag/entities/tag-tree-node';
import { type DocsService, Entity, LiveData } from '@toeverything/infra';

export class TagTree extends Entity {
  private readonly root: TagTreeNode;

  constructor(
    private readonly tagList: TagList,
    private readonly docs: DocsService
  ) {
    super();
    this.root = this.framework.createEntity(TagTreeNode, {
      displayName: TagTree.rootNodeName,
      prefix: '',
    });
  }

  private static readonly rootNodeName = 'tag';

  readonly rootNode$ = LiveData.from(this.tagList.tags$, []).map(tags => {
    this.root.clear();
    for (const tag of tags) {
      const tagPath = this.normalizeTagName(tag.value$.value);
      const nodeNameList = tagPath.split('/');
      let node = this.root;
      for (const nodeName of nodeNameList) {
        if (tagPath === '') {
          continue;
        }
        node = node.getOrCreateNode(nodeName);
      }
      if (node !== this.root) {
        node.addTag(tag);
      }
    }
    return this.root;
  });

  normalizeTagName(name: string) {
    // 1. convert '/+' to /
    name = name.replace(/\/+/g, '/');
    // 2. remove leading and suffix /
    name = name.replace(/^\/+|\/+$/g, '');
    return name;
  }

  getDocIdsByTagPath$(tagPath: string) {
    return LiveData.computed(get => {
      if (tagPath === undefined) {
        return [];
      }
      tagPath = tagPath.substring(TagTree.rootNodeName.length + 2); // always start with "/tag/", remove that
      const nodeNameList = tagPath.split('/');
      let node: TagTreeNode | undefined = this.root;
      for (const nodeName of nodeNameList) {
        if (nodeName === '' || node === undefined) {
          continue;
        }
        node = get(node.children$).get(nodeName);
      }
      if (node === this.root || node === undefined) {
        return [];
      }
      const tagIds = get(node.allTagIds$);
      return this.docs.list.docs$.value
        .filter(docRecord => {
          for (const docTagId of docRecord.meta$.value.tags || []) {
            if (tagIds.has(docTagId)) {
              return true;
            }
          }
          return false;
        })
        .map(docRecord => docRecord.id);
    });
  }
}
