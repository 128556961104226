import "../../core/src/components/page-list/docs/page-list-item.css.ts.vanilla.css!=!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/components/page-list/docs/page-list-item.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA71WbW/aMBD+zq+wJlUq0owCBcaMtv8xTdNk7Evi1bEj2yl0U//7ZDspTgi0dNW+JZd7ee7uubvMfs6rZdnoIkN/JghxYWtJHwnKJRy2E4SYltoQ9EDNLcY0z4UC7ODgcG1ERc0jDgpTr1qCKEpH0GpZB1PvAtvSCHVPUOYle8FdSdA8y278K5WiUFg4qCxB1hlwrPRyZ6iywgmtCNpRdl8Y3SgeI6FstrAfka4pE+4xvHkT/QAml3pPUCk4BxWgN8Z67Bxy2ki3nTxNZs/ZktKbhJyHIQbZBsXnNFMf3zl1FDMp2D3dSfjiTAM/gssudK2FcmDGzChz4iG1eSuMebDuir9cxeLvtOFgsKFcNJagTZSetLfXAgYRbIrlPAqcCymBT2M032nKfQN6+hWopv0SFCt6wC0L7tZZBFUJ1ckWUZZktwjZ1brjgwFJfeGiM1MIhSXkjiDcptgVoiOZbpwUCghSWgWrmnIuVNGabWK82BNuaFEkrTyiIDvItYHYXK0cKEfQhw/bHja6s1o2LkRxuiZoFSEEPufaVCQ+Surg2y1eZTehJhFIOiDLfirtazoW8dOZYXiBSbWkDEotecKnE8IsYszWdYD3iioFeqS1eu7G0N/8rL9EKFRxEiO4PcKard7qaAzmXQuzo+MY6OMgo6+I5MJYh1kpJI9M7bFrvh7QOdhIOmpiIobNwOQuVSIoQ6vrZrmgtZ+Ggdfl+Lo/4+N0ledaOWzFbxiQK8hLfNffUavxaMEtFwZYZDXTsqnUCY4Y3VHjtv1KxPL2tgqjkt362Uc4JD3t4oTuIbQvhQNsa8rCStgbWntxY8FgCxKY6zZFAn8dSTdyYy6WwVbTZ519y7B1ll2AkQANR/YYEqQUtRX2WBwLMk/OZgL301m4Z6+5BaYV79/zi7kd7Csr+w95bK7j6MuMHC+AYFqlaQ+4ngD6/H6A2vpd1ZCE+YNj153S3g9WApz+P+Cn/4Ujy8NLPEFSopxmd3kod1fl9KuxTuQeVnu8AwZQ/IWWs/eLErcbDvt4Pf6L/PQX2v5Q4o8LAAA=\"}!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var actionsCellWrapper = '_1m4huogb';
export var dateCell = '_1m4huoga';
export var dndCell = '_1m4huog2';
export var dragPageItemOverlay = '_1m4huog1';
export var iconCell = '_1m4huog8';
export var operationsCell = '_1m4huogc';
export var root = '_1m4huog0';
export var selectionCell = '_1m4huog4';
export var tagsCell = '_1m4huog9';
export var titleCell = '_1m4huog5';
export var titleCellMain = '_1m4huog6';
export var titleCellPreview = '_1m4huog7';
export var titleIconsWrapper = '_1m4huog3';