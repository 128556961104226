import { type DropTargetOptions } from '@affine/component';
import type { Tag } from '@affine/core/modules/tag';
import { TagService } from '@affine/core/modules/tag';
import type { TagTreeNode } from '@affine/core/modules/tag/entities/tag-tree-node';
import type { AffineDNDData } from '@affine/core/types/dnd';
import { useI18n } from '@affine/i18n';
import {
  GlobalContextService,
  useLiveData,
  useServices,
} from '@toeverything/infra';
import clsx from 'clsx';
import { useCallback, useMemo, useState } from 'react';

import { ExplorerTreeNode, type ExplorerTreeNodeDropEffect } from '../../tree';
import { ExplorerDocNode } from '../doc';
import type { GenericExplorerNode } from '../types';
// import { Empty } from './empty';
import { useExplorerTagNodeOperations } from './operations';
import * as styles from './styles.css';
// import { useFilterMetas } from '@affine/core/mobile/hooks/use-filter-docs';

export const ExplorerTagNode = ({
  tagId,
  tagNode,
  onDrop,
  location,
  reorderable,
  operations: additionalOperations,
  dropEffect,
  canDrop,
}: {
  tagId: string;
  tagNode?: TagTreeNode;
} & GenericExplorerNode) => {
  const t = useI18n();
  const { tagService, globalContextService } = useServices({
    TagService,
    GlobalContextService,
  });
  const active =
  useLiveData(globalContextService.globalContext.tagId.$) === `/tag/${tagId}`;
  const [collapsed, setCollapsed] = useState(true);

  if (!tagNode) {
    return null;
  }

  const tagColor = tagService.randomTagColor();
  const tagName = tagNode?.displayName || '';
  const childrenNode = Array.from(useLiveData(tagNode.children$).values());
  const tagRecord = useLiveData(tagService.tagList.tagByTagId$(''));

  const Icon = useCallback(
    ({ className }: { className?: string }) => {
      return (
        <div className={clsx(styles.tagIconContainer, className)}>
          <div
            className={styles.tagIcon}
            style={{
              backgroundColor: tagColor,
            }}
          ></div>
        </div>
      );
    },
    [tagColor]
  );

  const dndData = useMemo(() => {
    return {
      draggable: {
        entity: {
          type: 'tag',
          id: tagId,
        },
        from: location,
      },
      dropTarget: {
        at: 'explorer:tag',
      },
    } satisfies AffineDNDData;
  }, [location, tagId]);

  const handleDropOnTag = useCallback(() => {
    return;
  }, [onDrop, t, tagRecord]);

  const handleDropEffectOnTag = useCallback<ExplorerTreeNodeDropEffect>(
    data => {
      if (data.treeInstruction?.type === 'make-child') {
        if (data.source.data.entity?.type === 'doc') {
          return 'link';
        }
      } else {
        return dropEffect?.(data);
      }
      return;
    },
    [dropEffect]
  );

  // const handleDropOnPlaceholder = useCallback(
  //   (data: DropTargetDropEvent<AffineDNDData>) => {
  //     if (tagRecord) {
  //       if (data.source.data.entity?.type === 'doc') {
  //         tagRecord.tag(data.source.data.entity.id);
  //       } else {
  //         toast(t['com.affine.rootAppSidebar.tag.doc-only']());
  //       }
  //     }
  //   },
  //   [t, tagRecord]
  // );

  const handleCanDrop = useMemo<DropTargetOptions<AffineDNDData>['canDrop']>(
    () => args => {
      const entityType = args.source.data.entity?.type;
      return args.treeInstruction?.type !== 'make-child'
        ? ((typeof canDrop === 'function' ? canDrop(args) : canDrop) ?? true)
        : entityType === 'doc';
    },
    [canDrop]
  );

  const operations = useExplorerTagNodeOperations(
    tagId,
    useMemo(
      () => ({
        openNodeCollapsed: () => setCollapsed(false),
      }),
      []
    )
  );

  const finalOperations = useMemo(() => {
    if (additionalOperations) {
      return [...operations, ...additionalOperations];
    }
    return operations;
  }, [additionalOperations, operations]);

  const setCurrentTagId = () => {
    // tag 标签特殊需要先重置再触发保证状态得到更新
    globalContextService.globalContext.isTag.set(false);
    globalContextService.globalContext.tagId.set(null);
    const tagPath = tagNode.getTreePath();
    sessionStorage.setItem('memoryPath', JSON.stringify({ path: 'tag', tagPath: tagNode.getTreePath() }));
    globalContextService.globalContext.isTrash.set(false);
    globalContextService.globalContext.isAllDocs.set(false);
    globalContextService.globalContext.isFavorite.set(false);
    globalContextService.globalContext.isFolder.set(false);
    globalContextService.globalContext.folderId.set('');
    globalContextService.globalContext.docId.set('');
    globalContextService.globalContext.tagId.set(tagPath);
    globalContextService.globalContext.isTag.set(true);
  };

  // const { filtered } = useFilterMetas();
  // const tagDocIds = useLiveData(tagRecord.pageIds$);

  // const childrenSize = useMemo(() => {
  //   return filtered.filter(meta => tagDocIds.includes(meta.id)).length
  // }, [filtered, tagDocIds]);

  return (
    <ExplorerTreeNode
      icon={Icon}
      // childrenSize={childrenSize}
      // childrenSize={3}
      name={tagName || t['Untitled']()}
      disabled={false}
      dndData={dndData}
      onDrop={handleDropOnTag}
      renameable={false}
      collapsable={childrenNode.length > 0}
      collapsed={collapsed}
      setCollapsed={setCollapsed}
      // to={`/tag/${tagId}`}
      onClick={setCurrentTagId}
      active={active}
      reorderable={reorderable}
      canDrop={handleCanDrop}
      // childrenPlaceholder={<Empty onDrop={handleDropOnPlaceholder} />}
      operations={finalOperations}
      dropEffect={handleDropEffectOnTag}
      data-testid={`explorer-tag-${tagId}`}
    >
      {childrenNode.map(t => (
        <ExplorerTagNode
          key={t.getTreePath()}
          tagId={t.displayName}
          tagNode={t}
          reorderable={false}
          location={{
            at: 'explorer:tags:list',
          }}
        />
      ))}
    </ExplorerTreeNode>
  );
};

/**
 * the `tag.pageIds$` has a performance issue,
 * so we split the tag node children into a separate component,
 * so it won't be rendered when the tag node is collapsed.
 */
export const ExplorerTagNodeDocs = ({ tag }: { tag: Tag }) => {
  const tagDocIds = useLiveData(tag.pageIds$);
  return tagDocIds.map(docId => (
    <ExplorerDocNode
      key={docId}
      child={tag}
      docId={docId}
      reorderable={false}
      location={{
        at: 'explorer:tags:docs',
      }}
    />
  ));
};
