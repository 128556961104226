import type { DocMeta } from '@blocksuite/affine/store';
import {
  DocsService,
  LiveData,
  useLiveData,
  useServices,
} from '@toeverything/infra';

export const useFilterMetas = () => {
  const { docsService } = useServices({
    DocsService,
  });
  const docMetas = useLiveData(
    LiveData.computed(get => {
      return get(docsService.list.docs$).map(doc => get(doc.meta$) as DocMeta);
    })
  );

  const filtered = docMetas.filter(meta => {
    if (meta.trash) return false;
    return meta;
  });

  return { filtered };
};
