import type { DocMode } from '@blocksuite/affine/blocks';

import { Entity } from '../../../framework';
import { LiveData } from '../../../livedata';
import { MemoryMemento } from '../../../storage';

export class GlobalContext extends Entity {
  memento = new MemoryMemento();

  workspaceId = this.define<string>('workspaceId');

  /**
   * is in doc page
   */
  isDoc = this.define<boolean>('isDoc', false);
  isTrashDoc = this.define<boolean>('isTrashDoc', false);
  docId = this.define<string>('docId', null);
  docMode = this.define<DocMode>('docMode', null);

  /**
   * is in collection page
   */
  isCollection = this.define<boolean>('isCollection', false);
  collectionId = this.define<string>('collectionId', null);

  /**
   * is in folder page
   */
  isFolder = this.define<boolean>('isFolder', JSON.parse(sessionStorage.getItem('memoryPath') || '{}').path === 'folder');
  folderIds = this.define<string[]>('folderIds', JSON.parse(sessionStorage.getItem('memoryPath') || '{}').data || []);
  folderId = this.define<string>('folderId', JSON.parse(sessionStorage.getItem('memoryPath') || '{}').folderId || '');
  paths = this.define<string[]>('paths', JSON.parse(sessionStorage.getItem('memoryPath') || '{}').paths || []);

  /**
   * is in trash page
   */
  isTrash = this.define<boolean>('isTrash', false);

    /**
   * is in favorite page
   */
  isFavorite = this.define<boolean>('isFavorite', false);

  /**
   * is in tag page
   */
  isTag = this.define<boolean>('isTag', JSON.parse(sessionStorage.getItem('memoryPath') || '{}').path === 'tag');
  tagId = this.define<string>('tagId', JSON.parse(sessionStorage.getItem('memoryPath') || '{}').tagPath || '');

  /**
   * is in all docs page
   */
  isAllDocs = this.define<boolean>('isAllDocs', JSON.parse(sessionStorage.getItem('memoryPath') || '{}').path === 'all');

  define<T>(key: string, initialValue: T | null = null) {
    this.memento.set(key, initialValue);
    const livedata$ = LiveData.from(this.memento.watch<T>(key), initialValue);
    return {
      get: () => this.memento.get(key) as T | null,
      set: (value: T | null) => this.memento.set(key, value),
      $: livedata$,
    };
  }
}
