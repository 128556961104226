import "../../core/src/modules/explorer/views/tree/node.css.ts.vanilla.css!=!../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/modules/explorer/views/tree/node.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA61W7W7rKBD936dAkVZqtSJyPpq2VJX2PVbVipixMzcYLMDJzV3dd18Z7AQcxze73V8JZhiGM2fOzB97OBWGV2DJX4tTXm8Ox5z8/UBI9pv/IUTXPEd3YmTx/kDIzwdCNhN7L9d7Wb/3OnHubeLcIrt58OfDvA974U1yLbVhBNUODLr32GDpDQTaWvJTayJRAS0kfG99cYmlouigsozkoByY9vNWGwGGGi6wsYysa2/s4Luj/gQjEgr3PnIzIUcUbsd8+O2yQkV3gOXOMbLKgqPGgqEWJOSOEaUVeE+Nsa2rWmMfRs2FQFUykvUhFFo5avEHMHLg5pFSXhT+Pf579eRPaYsOtWLEgOQOD959xU2JijpdM+LDiDFiO30A45Ha8nxfGt0oMbjCm1D/3qf09J+CO0553l71MXOmgdnn13wJtHwrQSTeeoAEFLyRMfqJb58lC7lWgpvT+RLSA0vhAMrZHviRyw0vS1RlcvmFn/Pn5NAq5dcviNWRw5O8Ne0oPZ6ykteMLJaDZK39heOPiT3xrdWycf5ry9buVk8AuuiYeCZrWPZEXQwZ8hxAOIAppD4yskMhQJ2L4rIBUmJt0XrnO3RAbc1zaAM8Gl5PQBPBcTOttcGKm5NPp6/jPuDENNpJ2bX5V7kywXV2G9ZUtH7Nr67MBvGk6jb0ggodcpkGcf4YeX8Zf9y3xjos2jpQDpSLHzjN0eWAE/16LDmYa5UkJ9Kp5ZBLrz7QA1rcovQP79kUGb15o56emwE9u/W9mZzAYKD0y07pDVc91v5/oU1FsvnS3olA/JKgKrmWktd2oGln54wY7biDR/qWCShHXYyqYiRMq7tY+PYlseeXsEfxIcAtUFRUN2kf5l+HYZsy/OplW6aVPNF8h1IMu/51vQSTuC12ye+6Lg2iGWDpD2U3O2zkGaaxvWz0hIma1Ln4umob8HMzqKWiawaT8RQBemegzYx1pslb649ZxfcQ4Jp9BhsLsggNEIRX9Y9ZwaWF2SfjhetYcy6j2Sypwq3U+f62Wv6gqMRZ4++kXt+7xtIQZpkItZzL/NHPjfTqwFPa39rh7P+GKOzrGtQYalxhxQMql5l7YQmqoqUn+IbGTRJWGdLbj4Gvo5w4DxNUoIG8I4I+UgMHMHZilChvJ/UK0d+nEc3mz11wN2kfor4kdqud09Vkg7204CTqcQG70oOShZn0P0pdeddc66Wr5gZUqncYN7DVMm1g/Tpuk+tBcr55B5HFYlj8+yuLl+A2GY2W3V0SXNsX2nHMk4lm8/UqCuPY2a+zFG55pZPt/j+H08h0Qg4AAA==\"}!../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var collapseContentPlaceholder = '_1ycp6vwb';
export var collapsedIcon = '_1ycp6vwa';
export var collapsedIconContainer = '_1ycp6vw9';
export var collapsedIconContainerHide = '_1ycp6vw8';
export var contentContainer = '_1ycp6vwd';
export var draggedOverEffect = '_1ycp6vwf';
export var draggingContainer = '_1ycp6vwe';
export var iconContainer = '_1ycp6vw7';
export var itemContent = '_1ycp6vw5';
export var itemMain = '_1ycp6vw3';
export var itemRenameAnchor = '_1ycp6vw4';
export var itemRoot = '_1ycp6vw2';
export var levelIndent = 'var(--_1ycp6vw0)';
export var linkItemRoot = '_1ycp6vw1';
export var mobileCollapsedIconContainer = '_1ycp6vwj _1ycp6vw9';
export var mobileContentContainer = '_1ycp6vwl _1ycp6vwd';
export var mobileIconContainer = '_1ycp6vwi _1ycp6vw7';
export var mobileItemContent = '_1ycp6vwk _1ycp6vw5';
export var mobileItemMain = '_1ycp6vwh _1ycp6vw3';
export var mobileItemRoot = '_1ycp6vwg _1ycp6vw2';
export var postfix = '_1ycp6vw6';